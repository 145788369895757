import React, { useEffect, useState } from "react";
import Button from "../Buttons";
import styles from "./style.module.scss";

export default function AdmitAllowedLobbyMemberPopUp({
  setAdmitAllowedLobbyMember,
  children,
  setLobbyActive,
  askToJoinLobby,
}) {
  const [showAdmitUserPopUp, setShowAdmitUserPopUp] = useState(false);
  const [text, setText] = useState("");
  const [item, setItem] = useState(null);

  useEffect(() => {
    console.log("askToJoinLobby", askToJoinLobby);
    const name = askToJoinLobby[askToJoinLobby.length - 1]?.displayName;
    if (typeof name != "undefined") {
      setShowAdmitUserPopUp(true);
      setTimeout(() => {
        setShowAdmitUserPopUp(false);
      }, 25000);

      setText(`${name} has entered the Lobby for this meeting.`);
      setItem(askToJoinLobby[askToJoinLobby.length - 1]);
    } else {
      setShowAdmitUserPopUp(false);
    }
  }, [askToJoinLobby]);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {children}

      {showAdmitUserPopUp && (
        <div className={styles.ToolTipParentContainer}>
          {text}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "16px",
            }}
          >
            <Button.IconButton
              text={"Open Lobby"}
              style={{ backgroundColor: "#F0E6FF", color: "#8549FF", padding: "12px 12px" }}
              onClickHandler={() => {
                setLobbyActive(true);
                setShowAdmitUserPopUp(false);
              }}
            />
            <Button.IconButton
              onClickHandler={() => {
                setAdmitAllowedLobbyMember(item);
                setShowAdmitUserPopUp(false);
              }}
              text={"Admit"}
              style={{ backgroundColor: "#8549FF", color: "#FFFFFF", padding: "12px 12px", marginLeft: 12 }}
            />
          </div>
          <div className={styles.ToolTipTriangle} />
        </div>
      )}
    </div>
  );
}
