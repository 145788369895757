import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./style.module.scss";
import NamePlate from "../NamePlate";
import MuteIcon from "../MuteIcon";
import Button from "../Buttons";
import DropDownWithLabel from "../DropDown";

let screenStreamFromParent = null;

const LocalVideoStream = forwardRef(
  (
    {
      style,
      isLocalAudioMuted,
      isLocalVideoMuted,
      isSharingScreen,
      peerList,
      showSelectDevicePopUp,
      setShowSelectDevicePopUp,
      selectedAudio,
      setSelectedAudio,
      selectedVideo,
      setSelectedVideo
    },
    ref
  ) => {
    const videoRef = useRef();
    var localVideoStream;
    const [devices, setDevices] = useState({ audio: [], video: [] });
    
    const fetchDevices = async () => {
      try {
        const deviceInfos = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = deviceInfos.filter(
          (device) => device.kind === "audioinput"
        );
        const videoDevices = deviceInfos.filter(
          (device) => device.kind === "videoinput"
        );
        setDevices({
          audio: audioDevices,
          video: videoDevices,
        });
        if (audioDevices.length && selectedAudio === "") setSelectedAudio(audioDevices[0].deviceId);
        if (videoDevices.length && selectedVideo === "") setSelectedVideo(videoDevices[0].deviceId);
  
        
      } catch (error) {
        console.error("Failed to enumerate devices", error);
      }
    };
    const handleDeviceChange = (value, type) => {
      if (type === "audio") {
        setSelectedAudio(value);
      } else if (type === "video") {
        setSelectedVideo(value);
      }
    };
    const [constraints, setConstraints] = useState({audio : {} , video : {width: 1080,
      height: 720,}})
    useEffect(()=>{
      console.log("Updating Constraints" , constraints)
      setConstraints({
        
        audio: {
          deviceId: selectedAudio ? { exact: selectedAudio } : undefined,
        },
        video: {
          deviceId: selectedVideo ? { exact: selectedVideo } : undefined,
          width: 1080,
          height: 720,
        },
      })
    },[selectedAudio, selectedVideo])
    useEffect(()=>{
      if(showSelectDevicePopUp){
        fetchDevices();
      }
    },[showSelectDevicePopUp])
    useImperativeHandle(ref, () => ({
      getLocalStream() {
        return videoRef.current.srcObject;
      },
      updateScreenStream(screenStream) {
         screenStreamFromParent = screenStream;
      },
      updateVideoStream(localVideoStream) {
        if (videoRef && videoRef.current) {
          videoRef.current.srcObject = localVideoStream;
          handleLocalAudioMuted(); // Ensuring mute state is applied correctly
          handleLocalVideoMuted();
        }
      },
    }));

    useEffect(() => {
      if (!isSharingScreen) {
        if (!constraints.audio.deviceId || !constraints.video.deviceId) {
          fetchDevices();
        }
        console.log("Changing Media Devices", constraints)
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            localVideoStream = stream;
            if (videoRef && videoRef.current) {
              let video = videoRef.current;
              video.srcObject = stream;
            } else {
              console.log("videoRef is null");
            }
            handleLocalAudioMuted();
            handleLocalVideoMuted();
          })
          .catch((err) => {
            console.error("error:", err);
          });
      } else {
        if (screenStreamFromParent) {
          if (videoRef && videoRef.current) {
            let video = videoRef.current;
            video.srcObject = screenStreamFromParent;
          } else {
            console.log("videoRef is null");
          }

          handleLocalAudioMuted();
          handleLocalVideoMuted();
        }
      }
    }, [isSharingScreen , constraints]);
  

  
    useEffect(() => {
      return () => {
        if (localVideoStream) {
          localVideoStream?.getTracks().forEach(function (track) {
            track.stop();
          });
        }
      };
    }, []);

    useEffect(() => {
      if (videoRef && videoRef.current && videoRef.current.srcObject) {
        handleLocalAudioMuted();
      }
    }, [isLocalAudioMuted, videoRef.current?.srcObject]);

    useEffect(() => {
      if (videoRef && videoRef.current && videoRef.current.srcObject) {
        handleLocalVideoMuted();
      }
    }, [isLocalVideoMuted, videoRef.current?.srcObject]);

    const handleLocalAudioMuted = () => {
      let local_stream = videoRef.current.srcObject;
      local_stream.getAudioTracks().forEach((track) => {
        track.enabled = !isLocalAudioMuted;
      });
    };

    const handleLocalVideoMuted = () => {
      let local_stream = videoRef.current.srcObject;
      local_stream.getVideoTracks().forEach((track) => {
        track.enabled = !isLocalVideoMuted;
      });
    };

    return (
      <>
        {showSelectDevicePopUp && (
          <div
            style={{
              display: showSelectDevicePopUp ? "block" : "none",
            }}
            className={styles.popUpBox}
          >
            <h3 style={{textAlign: "center", paddingBottom: 16}}>Select Input Devices</h3>

            <h4>Audio</h4>

            <DropDownWithLabel
              labels={devices.audio.map(
                (device) => device.label || `Microphone ${device.deviceId}`
              )}
              values={devices.audio.map((device) => device?.deviceId)}
              initialValue={selectedAudio}
              onChange={(value) => handleDeviceChange(value, "audio")}
              style={{
                width: "95%",
                borderRadius: 12,
                height: "fit-content",
                alignSelf: "flex-end",
              }}
            />
            <h4>Video</h4>

            <DropDownWithLabel
              labels={devices.video.map(
                (device) => device.label || `Camera ${device.deviceId}`
              )}
              values={devices.video.map((device) => device?.deviceId)}
              initialValue={selectedVideo}
              onChange={(value) => handleDeviceChange(value, "video")}
              style={{
                width: "95%",
                borderRadius: 12,
                height: "fit-content",
                alignSelf: "flex-end",
              }}
            />

            <Button.IconButton
              style={{
                backgroundColor: "#8549FF",
                color: "white",
                padding: "1rem 1rem",
                marginTop: 32,
                paddingRight: "1rem",
                marginLeft: "calc(100% - 75px)"
              }}
              text={"Done"}
              onClickHandler={() => setShowSelectDevicePopUp(false)}
            />
          </div>
        )}
        <div
          id="local-video"
          style={style || { zIndex: 3000, position: "relative", height:"436px", width:"720px"
         }}
        >
          <video
            ref={videoRef}
            autoPlay={true}
            muted
            className={styles.videoStream}
            
          />
         {isLocalVideoMuted && (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.6)", 
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span
        style={{
          fontSize: "2rem",
          fontWeight: "bold",
          color: "white",
        }}
      >
        Y
      </span>
    </div>
  )}
          {/* {isLocalAudioMuted && <MuteIcon />} */}
          <NamePlate />
        </div>
      </>
    );
  }
);

export default LocalVideoStream;
