import { useEffect, useRef, useState } from "react";

import LocalVideoStream from "../../Components/LocalVideoStream";
import Header from "../../Components/HeaderComponent";
import styles from "./style.module.scss";
import ControlButton from "../../Components/ControlButtons";
import NamePlate from "../../Components/NamePlate";
import Button from "../../Components/Buttons";
import Popup from "../../Components/Popup";
import { Buttons } from "@lana-health/lana-react-component-library";

export default function CheckpointScreen({
  _token,
  isLocalAudioMuted,
  setIsLocalAudioMuted,
  guestDisplayName,
  setGuestDisplayName,
  isLocalVideoMuted,
  selectedAudio,
  selectedVideo,
  setSelectedAudio,
  setSelectedVideo,
  setIsLocalVideoMuted,
  showSelectDevicePopUp,
  setShowSelectDevicePopUp,
  patientName,
  setHasAskedToJoin,
  setHasVideoCallJoined,
  joinedPeerListCount,
}) {
  var videoRefFromChild = null;
  const [isPopupOpen, setPopupOpen] = useState(false);

  const [askToJoinDisabled, setAskToJoinDisabled] = useState(true);

  const [askedToJoin, setAskedToJoin] = useState(false);

  useEffect(() => {
    guestDisplayName !== ""
      ? setAskToJoinDisabled(false)
      : setAskToJoinDisabled(true);
  }, [guestDisplayName]);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div style={{gap:"108px"}}>
        <Header imgSource={"/logo-2.png"} />
        <div className={styles.mainContainer}>
          <div className={styles.localVideoContainer}>
            <LocalVideoStream
              isLocalAudioMuted={isLocalAudioMuted}
              isLocalVideoMuted={isLocalVideoMuted}
              selectedAudio={selectedAudio}
              selectedVideo={selectedVideo}
              setSelectedAudio={setSelectedAudio}
              setSelectedVideo={setSelectedVideo}
              showSelectDevicePopUp={showSelectDevicePopUp}
              setShowSelectDevicePopUp={setShowSelectDevicePopUp}
              updateVideoRef={(videoRef) => {
                videoRefFromChild = videoRef;
              }}
            />
            <ControlButton
              withEndCallBttn={false}
              isLocalAudioMuted={isLocalAudioMuted}
              isLocalVideoMuted={isLocalVideoMuted}
              setShowSelectDevicePopUp={setShowSelectDevicePopUp}
              setIsLocalAudioMuted={setIsLocalAudioMuted}
              setIsLocalVideoMuted={setIsLocalVideoMuted}
            />
          </div>
          <div className={styles.localDetailsContainer}>
            <h2>Room 306-A</h2>
            {joinedPeerListCount && joinedPeerListCount > 0 ? (
              <h5>
                {joinedPeerListCount}{" "}
                {joinedPeerListCount == 1 ? (
                  <>other person has</>
                ) : (
                  <>others have</>
                )}{" "}
                joined the call
              </h5>
            ) : (_token && 
            <h5>
                  <img
                    src="/assets/loader.svg"
                    className={styles.loaderImage}
                  />
                  Waiting for others to join
             </h5>
            )}
            {!_token && (
              <div className={styles.InputLabelBoxContainer}>
                <div className={styles.InputBoxDiv}>
                  <input
                    name="name"
                    value={guestDisplayName}
                    onChange={(e) => setGuestDisplayName(e.target.value.slice(0, 40))}
                    required
                    type="text"
                    className={styles.Input}
                    placeholder="Enter your name"
                    maxLength={40}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
               justifyContent:"center",
               alignItems:"center",
              gap: "16px",
               marginTop:"16px",
              }}
            >
              {_token ? (
                <>
               
                   <Buttons.StandardButtonAlt
                    primary400color={"#8549FF"} primary600color={"#6323E6"}
                    Icon={<img src={"/assets/icn-video.svg"} width={32} height={32}></img>} 
                    style={{
                      padding:"12px 16px 12px 16px",
                      borderRadius:"12px",
                      width:"200px",
                      justifyContent:"center",
                    }}
                    text={"Join Session"}
                    onClickHandler={()=> setHasVideoCallJoined(true)}
                    
                />
                
                <Buttons.StandardButton primary400color={"#8549FF"} primary100color={"#E3D1FF"} primary50color={"#F0E6FF"}
                    text={"Invite Others"}
                    style={{
                      padding:"12px 16px 12px 16px",
                      borderRadius:"12px",
                      width:"200px",
                      justifyContent:"center",
                    }}
                    onClickHandler={handleOpenPopup}
                    Icon={<img src={"/assets/icn-add-contact.svg"} width={32} height={32}></img>} 
                />
                  
                </>
              ) : !askedToJoin ? (
                <Button.IconButton
                  icon={"/assets/icn-video.svg"}
                  style={
                    askToJoinDisabled
                      ? {
                          opacity: "0.2",
                          cursor: "not-allowed",
                          pointerEvents: "none",
                          flexDirection: "row",
                          backgroundColor: "#8549FF",
                          color: "white",
                          padding: "12px",
                          width: "240px",
                        }
                      : {
                          flexDirection: "row",
                          backgroundColor: "#8549FF",
                          color: "white",
                          padding: "12px",
                          width: "240px",
                        }
                  }
                  onClickHandler={() => {
                    setAskedToJoin(true);
                    setHasAskedToJoin(true);
                    setAskToJoinDisabled(true);
                  }}
                  text={"Ask To Join"}
                />
              ) : (
                <h5>
                  <img
                    src="/assets/loader.svg"
                    className={styles.loaderImage}
                  />
                  Waiting for someone to admit you to the call
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
      <Popup isOpen={isPopupOpen} onClose={handleClosePopup} />
    </>
  );
}
