import styles from "./style.module.scss";
export default function AddNew({ formData, handleChange }) {
  return (
    <>
      <div className={styles.HeaderTitle}>
        Add a new contact
        <p className={styles.HeaderSubtitle}>
          {" "}
          Ask a new contact to join this session
        </p>
      </div>
      <div className={styles.formSection}>
        <div className={styles.InputRow}>
          <div className={styles.InputLabelBoxContainer}>
            <div style={{ margin: "0", padding: "0", display: "flex" }}>
              {" "}
              First Name{" "}
              <span style={{ display: "inline", color: "red" }}>*</span>
            </div>
            <div className={styles.InputBoxDiv}>
              <input
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                type="text"
                className={styles.Input}
                placeholder="Full name"
              />
            </div>
          </div>
          {/* <div className={styles.InputLabelBoxContainer}>
            <div style={{ margin: "0", padding: "0", display: "flex" }}>
              {" "}
              Last Name{" "}
              <span style={{ display: "inline", color: "red" }}>*</span>
            </div>
            <div className={styles.InputBoxDiv}>
              <input
                required
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                type="text"
                className={styles.Input}
                placeholder="Enter last name"
              />
            </div>
          </div> */}
        </div>
        <div className={styles.InputRow}>
          {/* <div className={styles.InputLabelBoxContainer}>
            <div style={{ margin: "0", padding: "0", display: "flex" }}>
              {" "}
              Phone Number{" "}
              <span style={{ display: "inline", color: "red" }}>*</span>
            </div>
            <div className={styles.InputBoxDiv}>
              <p className={styles.InputFont}>+ 1</p>
              <div
                style={{
                  width: "1px",
                  height: "24px",
                  backgroundColor: "#DEDEDE",
                }}
              />
              <input
                required
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                type="tel"
                className={styles.Input}
                placeholder="(000) 000-0000"
              />
            </div>
          </div> */}
          <div className={styles.InputLabelBoxContainer}>
            <div style={{ margin: "0", padding: "0", display: "flex" }}>
              {" "}
              Email ID{" "}
              <span style={{ display: "inline", color: "red" }}>*</span>
            </div>
            <div className={styles.InputBoxDiv}>
              <input
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
                type="email"
                className={styles.Input}
                placeholder="Enter email"
              />
            </div>
          </div>
        </div>
        <div className={styles.InputRow}>
          <div className={styles.InputLabelBoxContainer}>
            <div style={{ margin: "0", padding: "0", display: "flex" }}>
              {" "}
              Relationship to Patient{" "}
              <span style={{ display: "inline", color: "red" }}>*</span>
            </div>
            <div
              className={styles.InputBoxDiv}
              style={{
                position: "relative",
              }}
            >
              <select
                required
                name="relation"
                value={formData.relation}
                onChange={handleChange}
                type="text"
                className={`${styles.SelectBox} ${styles.Input}`}
                style={{
                  zIndex: "1px",
                }}
                placeholder="Select"
              >
                <option value="" disabled selected>
                  Select
                </option>
                <option value="Spouse">Spouse</option>
                <option value="Parent">Parent</option>
                <option value="Sibling">Sibling</option>
                <option value="Child">Child</option>
                <option value="Guardian">Guardian</option>
                <option value="Other Family">Other Family</option>
                <option value="Friend">Friend</option>
                <option value="Other">Other</option>
              </select>
              <img
                src="/assets/icn-dropdown.svg"
                style={{
                  position: "absolute",
                  right: "0px",
                  zIndex: "0",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
