import { useEffect, useRef, useState } from "react";
import "./LanaWebrtcApp.css";
import Chatroom from "./Views/Chatroom";
import CheckpointScreen from "./Views/CheckpointScreen";
import { io } from "socket.io-client";
import CallEndedScreen from "./Views/CallEndedScreen";
import ErrorPopUp from "./Components/ErrorPopUp";
import API_ENDPOINTS from "./API/endpoints";
import { useCookies } from "react-cookie";

var videoRefFromChild = null;
var mySid = null;

// socketio
var protocol = window.location.protocol;

// var socketEndpoint = protocol + "//" + document.domain + ":" + "8056"; // for local

// var socketEndpoint = "https://dev-antst.lana.health"
var socketEndpoint = API_ENDPOINTS.SOCKET_URL;
console.log("socketEndpoint", socketEndpoint);

var socket = io(socketEndpoint, {
  transports: ["websocket"],
});

var socketInitialized = false;
var peerList = {};
var peerIdIsPatient = {};
var peerIdDisplayName = {};
let localStream;
let localVideoStream = null;

let localAudioGainNode = null;
let audioContext;
let audioDestination;
let participantStreams = new Map();
let currentRecordingId = null;
let localAudioStreamForRecording;
let peerConnectionForRecording;

function LanaWebrtcApp({ token, setToken }) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLocalAudioMuted, setIsLocalAudioMuted] = useState(false);
  const [isLocalVideoMuted, setIsLocalVideoMuted] = useState(false);
  const [isPatientAudioMuted, setIsPatientAudioMuted] = useState(false);
  const [isPatientVideoMuted, setIsPatientVideoMuted] = useState(false);
  const [hasVideoCallJoined, setHasVideoCallJoined] = useState(false);
  const [hasAskedToJoin, setHasAskedToJoin] = useState(false);
  const [hasVideoCallEnded, setHasVideoCallEnded] = useState(false);
  const [joinedPeerListCount, setJoinedPeerListCount] = useState(0);
  const [totalCallTime, setTotalCallTime] = useState("");
  const [participantStates, setParticipantStates] = useState({});
  const [askToJoinLobby, setAskedToJoinLobby] = useState([]);
  const [patientName, setPatientName] = useState("Jerry K.");
  const [patientViewUrl, setPatientViewUrl] = useState("");
  const searchParams = new URLSearchParams(document.location.search);
  const chatroomRef = useRef();
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [admitAllowedLobbyMember, setAdmitAllowedLobbyMember] = useState(null);
  const [declineDiscardedLobbyMember, setDeclineDiscardedLobbyMember] =
    useState(null);
  const [guestDisplayName, setGuestDisplayName] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isSharingScreen, setIsSharingScreen] = useState(false);
  const [isSomeOneElseRecording, setIsSomeOneElseRecording] = useState(false);
  const [peerSharingScreenId, setPeerSharingScreenId] = useState(null);
  const [showSelectDevicePopUp, setShowSelectDevicePopUp] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState("");
  const [selectedVideo, setSelectedVideo] = useState("");
  const mediaRecorderRef = useRef(null);
  var _callId = searchParams.get("callId");
  var _roomId = searchParams.get("roomId");
  var _hospitalId = searchParams.get("hospitalId");
  var _practitionerId = searchParams.get("pracId");
  const audioToPatientDisabled = true;

  function generateUniqueRecordingId() {
    return (
      "rec_" + new Date().getTime() + "_" + Math.floor(Math.random() * 10000)
    );
  }
  const collectAvailableRecordingData = (event, recordingID) => {
    if (event.data.size > 0) {
      const blob = event.data;

      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;

        socket.emit("recorded-data-chunk", {
          arrayBuffer,
          recordingID,
          _callId,
        });
      };
      reader.readAsArrayBuffer(blob);
    }
  };
  function addParticipantStream(peerId, stream) {
    participantStreams.set(peerId, stream);
  }
  function removeParticipantStream(peerId) {
    participantStreams.delete(peerId);
  }
  const addToRecordingMix = (newAudioStream) => {
    if (audioContext.state === "suspended") {
      audioContext.resume();
    }
    const newAudioSource = audioContext.createMediaStreamSource(newAudioStream);

    newAudioSource.connect(audioDestination);
  };

  const startRecording = async (calledByRecordingInitiator = true) => {
    try {
      //   currentRecordingId = generateUniqueRecordingId();
      currentRecordingId = _callId;
      console.log("Creating new RTCPeerConnection for recording");
      console.log("callRecordingId: ", currentRecordingId);
      peerConnectionForRecording = new RTCPeerConnection(PC_CONFIG);

      peerConnectionForRecording.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit("ice-candidate-for-recorder", {
            callRecordingId: currentRecordingId,
            candidate: event.candidate,
          });
        }
      };

      peerConnectionForRecording.onnegotiationneeded = async () => {
        const offer = await peerConnectionForRecording.createOffer();
        await peerConnectionForRecording.setLocalDescription(offer);
        console.log(
          "Sending offer:",
          peerConnectionForRecording.localDescription
        );
        socket.emit("initiate-call-recording-and-send-offer", {
          callRecordingId: currentRecordingId,
          offer: peerConnectionForRecording.localDescription,
        });
      };

      localAudioStreamForRecording = await chatroomRef.current.getLocalStream();
      localAudioStreamForRecording.getTracks().forEach((track) => {
        peerConnectionForRecording.addTrack(
          track,
          localAudioStreamForRecording
        );
      });
      console.log("Starting recording");
      if (calledByRecordingInitiator) {
        console.log("Emiting call-recording-start");
        socket.emit("call-recording-start", {
          recordingId: currentRecordingId,
          roomId: _roomId,
        });
        setIsRecording(true);
      }
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  socket.on("answer-from-recorder", async (answer) => {
    try {
      console.log("Received answer from recorder server:", answer);
      await peerConnectionForRecording.setRemoteDescription(answer.answer);
    } catch (error) {
      console.error("Error setting remote description:", error);
    }
  });

  socket.on("candidate-for-peer", async (candidate) => {
    console.log("Received ICE candidate:", candidate);
    try {
      await peerConnectionForRecording.addIceCandidate(
        new RTCIceCandidate(candidate)
      );
    } catch (error) {
      console.error("Error adding ICE candidate:", error);
    }
  });

  useEffect(() => {
    if (isRecording) {
      console.log("checking Mute Status");
    }
  }, [isLocalAudioMuted, isRecording]);

  function mutePeer(
    peerId,
    isPatient,
    audioMuted,
    videoMuted,
    isScreenBeingShared
  ) {
    let participantStateData = {
      practitionerId: _practitionerId,
      peerId: peerId,
      isPatient: isPatient,
      audioMuted: audioMuted,
      videoMuted: videoMuted,
      isScreenBeingShared: isScreenBeingShared,
    };

    if (isAdmin) {
      console.log("Emitting mute Peer", participantStateData);
      socket.emit("mute-peer", participantStateData);
    }
  }
  function removePeer(peerId) {
    socket.emit("disconnect-peer", {
      peerId: peerId,
      callId: _callId,
      hospitalId: _hospitalId,
    });
  }
  function videoCallEndedSelf() {
    if (isRecording) {
      stopRecording();
    }
    if (isSharingScreen) {
      setPeerSharingScreenId(null);
      stopScreenShare();
    }
    Object.values(peerList).forEach((peerConnection) => {
      if (peerConnection) {
        console.log("Closing peer connection.");
        peerConnection.close();
      }
    });
    peerList = {};
    if (localStream) {
      localStream.getTracks().forEach((track) => {
        track.stop();
      });
      localStream = null;
    }
    if (socket.connected) {
      socket.disconnect();
    }
    setHasVideoCallEnded(true);
  }
  const stopRecording = () => {
    return new Promise((resolve, reject) => {
      if (isRecording) {
        console.log("Stopping recording");
        socket.emit("recording-stopped", {
          recordingID: currentRecordingId,
          callId: _callId,
          roomId: _roomId,
        });
        currentRecordingId = null;
        setIsRecording(false);
        resolve();
      } else {
        reject("Recording is not active");
      }
    });
  };
  const openErrorPopup = () => {
    setIsErrorPopupOpen(true);
  };

  const closePopup = () => {
    setIsErrorPopupOpen(false);
  };
  function isChrome() {
    return /chrome|chromium|crios/i.test(navigator.userAgent);
  }

  var PC_CONFIG = {
    iceServers: [
      isChrome()
        ? { url: "stun:dev-stun.lana.health:3478" }
        : { urls: "stun:dev-stun.lana.health:3478" },
      {
        urls: "turn:dev-turn.lana.health:3478",
        username: "lana-turn-server",
        credential: "somepassword",
      },
    ],
  };

  function sendViaServer(data) {
    console.log("Sending via server: ", JSON.stringify(data));
    socket.emit("data", JSON.stringify(data));
  }

  useEffect(() => {
    if (socketInitialized) {
      return;
    }
    socketInitialized = true;

    console.log("socket connected....");

    socket.on("connect", () => {
      var data = {
        practitionerId: _practitionerId,
        patientId: null,
        roomId: _roomId,
        callId: _callId,
        hospitalId: _hospitalId,
        callParticipantToken: token,
        audioMuted: isLocalAudioMuted,
        videoMuted: isLocalVideoMuted,
        callParticipantStatus: "WAITING_IN_LOBBY",
      };

      if (token) {
        console.log("emitting join-waiting-room-v2");
        socket.emit("join-waiting-room-v2", data);
      } else {
        console.log("token is empty");
      }
    });

    socket.on("ask-to-join-call-user-list", (data) => {
      console.log("Users in ask to join lobby", JSON.stringify(data));
      setAskedToJoinLobby(data?.list);
    });
    socket.on("is-call-admin", (boolValue) => {
      console.log("Is Admin Of the call", boolValue);
      setIsAdmin(boolValue);
    });

    socket.on("user-connect", (data) => {
      console.log("user-connect ", data);
      let peerId = data["sid"];
      let displayName = data["name"];
      let isPatient = data["isPatient"];
      var audioMuted = data["audioMuted"];
      var videoMuted = data["videoMuted"];
      var isScreenBeingShared = false;
      peerList[peerId] = undefined; // add new user to user list
      peerIdDisplayName[peerId] = displayName;
      peerIdIsPatient[peerId] = isPatient;

      if (isPatient) {
        setIsPatientAudioMuted(audioMuted);
        setIsPatientVideoMuted(videoMuted);
      }
      console.log(peerList);
      console.log("Updated peer list", peerList);
      console.log("Calling setJoinedPeerListCount", peerList);
      chatroomRef.current.addDiv(
        peerId,
        peerIdDisplayName[peerId],
        peerIdIsPatient[peerId]
      );
      setJoinedPeerListCount(Object.keys(peerList).length);
      setParticipantStates((prevStates) => {
        const newState = { ...prevStates };
        if (newState[peerId]) {
          newState[peerId] = {
            ...newState[peerId],
            audioMuted,
            videoMuted,
            isScreenBeingShared,
            isPatient,
          };
        } else {
          newState[peerId] = {
            audioMuted,
            videoMuted,
            displayName,
            isScreenBeingShared,
            isPatient,
          };
        }
        return newState;
      });
    });

    socket.on("user-disconnect", (data) => {
      console.log("user-disconnect ", data);
      let peerId = data["sid"];
      if (peerId === mySid) {
        videoCallEndedSelf();
        setToken("");
      } else {
        closeConnection(peerId);
        removeParticipantStream(peerId);
        console.log("Participant Stremas Map", participantStreams);
        setParticipantStates((prevStates) => {
          const newStates = { ...prevStates };
          delete newStates[peerId];
          return newStates;
        });
        setJoinedPeerListCount(Object.keys(peerList).length);
        if (chatroomRef && chatroomRef.current) {
          chatroomRef.current.removeVideoElement(peerId);
        }
      }
    });

    socket.on("user-allowed-to-join", (data) => {
      console.log("Event received: user-allowed-to-join");

      setToken(data.callParticipantToken);

      setHasVideoCallJoined(true);
    });

    socket.on("is-someone-recording", (data) => {
      console.log("Some One Else Recording: ", data);
      setIsSomeOneElseRecording(data?.isCallBeingRecorded);
      if (data?.isCallBeingRecorded === true) startRecording(false);
    });
    socket.on("update-audio-video-state", (data) => {
      console.log("Update My state", data);
      if (data && data.peerId === mySid) {
        setIsLocalAudioMuted(data.audioMuted);
        setIsLocalVideoMuted(data.videoMuted);
      }
    });
    socket.on("user-list", (data) => {
      console.log("user list recvd ", data);
      let _mySid = data["my_sid"];
      mySid = _mySid;

      if ("list" in data) {
        // not the first to connect to room, existing user list recieved
        let recvd_list = data["list"];
        var otherUsersFound = false;
        // add existing users to user list
        for (let peerId in recvd_list) {
          console.log("Comparing " + peerId + " " + _mySid);
          if (peerId !== _mySid) {
            var displayName = recvd_list[peerId]["displayName"];
            var isPatient = recvd_list[peerId]["isPatient"];
            var audioMuted = recvd_list[peerId]["audioMuted"];
            var videoMuted = recvd_list[peerId]["videoMuted"];
            var isScreenBeingShared = recvd_list[peerId]["isScreenBeingShared"];
            console.log(
              "Is Screenshare variable correct ",
              isScreenBeingShared
            );
            peerList[peerId] = undefined;
            peerIdDisplayName[peerId] = displayName;
            peerIdIsPatient[peerId] = isPatient;
            if (isPatient) {
              setIsPatientAudioMuted(audioMuted);
              setIsPatientVideoMuted(videoMuted);
            }
            console.log("2 Setting otherUsersFound = true");
            otherUsersFound = true;
            setJoinedPeerListCount(Object.keys(peerList).length);
            if (isScreenBeingShared) {
              console.log("Updating Who is Sharing Screen", peerId);
              setPeerSharingScreenId(peerId);
            }

            console.log("setting participant state in user-list");
            setParticipantStates((prevStates) => ({
              ...prevStates,
              [peerId]: {
                audioMuted,
                videoMuted,
                displayName,
                isScreenBeingShared,
                isPatient,
              },
            }));
          }
          // todo: add video frame
        }
        console.log("Updated peer list2", peerList);
        if (otherUsersFound) {
          console.log("Calling setJoinedPeerListCount2", peerList);
        }
        start_webrtc();
      }
    });

    socket.on("waiting-user-list", (data) => {
      console.log("waiting user list recvd ", data);

      if ("list" in data) {
        let recvd_list = data["list"];
        console.log("recvd_list ", recvd_list);
        console.log("Calling setJoinedPeerListCount2", recvd_list);
        setPatientViewUrl(data["patientViewUrl"]);
        setJoinedPeerListCount(Object.keys(recvd_list).length);
      }
    });

    socket.on("join-call-failed", (data) => {
      console.log("Failed to Join Call", data);
      openErrorPopup();
      setToken("");
    });

    socket.on("data", (msg) => {
      console.log("socket.on(data) : ", msg);
      switch (msg.type) {
        case "SessionDescription":
          switch (msg.payload.type) {
            case "offer":
              console.log("handelling offer");
              handleOfferMsg(msg);
              break;
            case "answer":
              console.log("handelling answer");
              handleAnswerMsg(msg);
              break;
          }
          break;
        case "IceCandidate":
          console.log("handelling IceCandidate");
          handleNewICECandidateMsg(msg);
          break;
      }
    });

    socket.on("participant-state-data", (data) => {
      console.log("StateChangeData participant state data received ", data);

      if (data && data["peerId"] && data["peerId"] === mySid) {
        console.log("peerId is same as mySid, doing nothing");
        return;
      }

      if (data && data["isPatient"] === true) {
        setIsPatientAudioMuted(data["audioMuted"]);
        setIsPatientVideoMuted(data["videoMuted"]);
      }
      const { peerId, audioMuted, videoMuted, isScreenBeingShared } = data;

      if (data["isScreenBeingShared"] === true) {
        console.log("Updating Who is Sharing Screen", peerId);
        setPeerSharingScreenId(peerId);
      } else {
        console.log(
          "Setting peer sharing screen null",
          peerId,
          peerSharingScreenId
        );
        if (peerId === peerSharingScreenId || !peerSharingScreenId) {
          console.log("Success in Setting ScreenShare Null");
          setPeerSharingScreenId(null);
        }
      }

      var displayName = peerIdDisplayName[peerId];
      var isPatient = peerIdIsPatient[peerId];
      setParticipantStates((prevStates) => {
        const newState = { ...prevStates };
        if (newState[peerId]) {
          newState[peerId] = {
            ...newState[peerId],
            audioMuted,
            videoMuted,
            isScreenBeingShared,
          };
        } else {
          newState[peerId] = {
            audioMuted,
            videoMuted,
            displayName,
            isScreenBeingShared,
            isPatient,
          };
        }
        return newState;
      });
    });
  }, []);

  useEffect(() => {
    if (hasAskedToJoin) {
      var data = {
        roomId: _roomId,
        callId: _callId,
        hospitalId: _hospitalId,
        displayName: guestDisplayName,
        localAudioMuted: isLocalAudioMuted,
        localVideoMuted: isLocalVideoMuted,
      };
      console.log("Emitting ask to join ");
      socket.emit("ask-to-join-call", data);
    }
  }, [hasAskedToJoin]);
  useEffect(() => {
    if (hasVideoCallJoined) {
      socket.emit("leave-waiting-room-v2", { roomId: _roomId });

      var data = {
        displayName: guestDisplayName ? guestDisplayName : _roomId,
        practitionerId: _practitionerId,
        patientId: null,
        roomId: _roomId,
        callId: _callId,
        hospitalId: _hospitalId,
        callParticipantToken: token,
        audioMuted: isLocalAudioMuted,
        videoMuted: isLocalVideoMuted,
        isPatient: false,
      };
      socket.emit("join-room-v2", data);
    }
  }, [hasVideoCallJoined]);
  useEffect(() => {
    if (admitAllowedLobbyMember) {
      var data = {
        socketId: admitAllowedLobbyMember.socketId,
        practitionerId: _practitionerId,
        approverCallParticipantToken: token,
        patientId: null,
        roomId: _roomId,
        callId: _callId,
        hospitalId: _hospitalId,
        audioMuted: admitAllowedLobbyMember.localAudioMuted,
        videoMuted: admitAllowedLobbyMember.localVideoMuted,
        isPatient: false,
        displayName: admitAllowedLobbyMember.displayName,
      };
      console.log("Attempting to admit user");
      socket.emit("allow-user-join-room", data);
      console.log(askToJoinLobby);
    }
  }, [admitAllowedLobbyMember]);
  useEffect(() => {
    if (declineDiscardedLobbyMember) {
      var data = {
        hospitalId: _hospitalId,
        callId: _callId,
        joinRequesterSid: declineDiscardedLobbyMember.socketId,
        roomId: _roomId,
      };
      console.log("Attempting to decline user from joining");
      socket.emit("decline-user-join-room", data);
      console.log(askToJoinLobby);
    }
  }, [declineDiscardedLobbyMember]);

  useEffect(() => {
    if (hasVideoCallJoined) {
      let participantStateData = {
        hospitalId: _hospitalId,
        peerId: mySid,
        isPatient: false,
        audioMuted: isLocalAudioMuted,
        videoMuted: isLocalVideoMuted,
        isScreenBeingShared: false,
        callId: _callId,
      };
      console.log("Emiting participant state data", participantStateData);
      socket.emit(
        "participant-state-data",
        JSON.stringify(participantStateData)
      );
    }
  }, [isLocalAudioMuted, isLocalVideoMuted]);
  useEffect(() => {
    function switchMediaTracks() {
      const currentStream = localStream;

      if (!currentStream) return;

      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: selectedAudio ? { exact: selectedAudio } : undefined,
          },
          video: isSharingScreen
            ? false
            : {
                deviceId: selectedVideo ? { exact: selectedVideo } : undefined,
                width: 1080,
                height: 720,
              },
        })
        .then((newStream) => {
          const oldAudioTrack = currentStream.getAudioTracks()[0];
          const newAudioTrack = newStream.getAudioTracks()[0];
          if (oldAudioTrack && newAudioTrack) {
            Object.values(peerList).forEach((pc) => {
              pc.getSenders().forEach((sender) => {
                if (sender.track.kind === "audio") {
                  sender.replaceTrack(newAudioTrack);
                }
              });
            });

            oldAudioTrack.stop();
          }

          if (!isSharingScreen) {
            const oldVideoTrack = currentStream.getVideoTracks()[0];
            const newVideoTrack = newStream.getVideoTracks()[0];
            if (oldVideoTrack && newVideoTrack) {
              Object.values(peerList).forEach((pc) => {
                pc.getSenders().forEach((sender) => {
                  if (sender.track.kind === "video") {
                    sender.replaceTrack(newVideoTrack);
                  }
                });
              });
            }
            oldVideoTrack.stop();
          }

          chatroomRef.current.updateScreenStream(currentStream);
        })
        .catch((e) => console.error("Failed to switch media device: ", e));
    }
    switchMediaTracks();
  }, [selectedAudio, selectedVideo, isSharingScreen]);

  function invite(peer_id) {
    if (peerList[peer_id]) {
      console.log(
        "[Not supposed to happen!] Attempting to start a connection that already exists!"
      );
    } else if (peer_id === mySid) {
      console.log("[Not supposed to happen!] Trying to connect to self!");
    } else {
      console.log(`Creating peer connection for <${peer_id}> ...`);
      createPeerConnection(peer_id);

      console.log(`Adding local_stream`);
      console.log("videoRefFromChild", videoRefFromChild);

      let local_stream = chatroomRef.current.getLocalStream();
      if (audioToPatientDisabled && peerIdIsPatient[peer_id]) {
        let videoTrack = local_stream.getVideoTracks()[0];
        if (videoTrack) {
          peerList[peer_id].addTrack(videoTrack, local_stream);
        }
      } else {
        local_stream?.getTracks()?.forEach((track) => {
          console.log("Adding track for peer_id", peer_id);
          peerList[peer_id].addTrack(track, local_stream);
        });
      }
    }
  }
  function toggleScreenShare() {
    if (!isSharingScreen) {
      startScreenShare();
    } else {
      stopScreenShare();
    }
  }

  function notifyScreenShareToggle(isSharing) {
    let participantStateData = {
      peerId: mySid,
      isPatient: false,
      hospitalId: _hospitalId,
      audioMuted: isLocalAudioMuted,
      videoMuted: isLocalVideoMuted,
      isScreenBeingShared: isSharing,
      callId: _callId,
    };
    console.log("Emitting participant state data", participantStateData);
    socket.emit("participant-state-data", JSON.stringify(participantStateData));
  }

  function startScreenShare() {
    localVideoStream = chatroomRef.current.getLocalStream();
    let screenStream, voiceStream;

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((audioStream) => {
        voiceStream = audioStream;
        return navigator.mediaDevices.getDisplayMedia({
          video: { cursor: "always" },
        });
      })
      .then((stream) => {
        screenStream = stream;

        const existingAudioTrack = screenStream.getAudioTracks()[0];
        if (!existingAudioTrack) {
          const audioTrack = voiceStream.getAudioTracks()[0];
          screenStream.addTrack(audioTrack);
        }

        Object.values(peerList).forEach((pc) => {
          pc.getSenders().forEach((sender) => {
            if (sender.track.kind === "video") {
              sender.replaceTrack(screenStream.getVideoTracks()[0]);
            } else if (sender.track.kind === "audio") {
              sender.replaceTrack(screenStream.getAudioTracks()[0]);
            }
          });
        });

        chatroomRef.current.updateScreenStream(screenStream);

        setIsSharingScreen(true);
        notifyScreenShareToggle(true);

        screenStream.getVideoTracks()[0].onended = stopScreenShare;
      });
  }
  async function stopScreenShare() {
    // First, update the flag saying sharing is stopping.
    notifyScreenShareToggle(false);
    let screenStream = chatroomRef.current.getLocalStream();
    screenStream.getTracks().forEach((track) => track.stop());
    setIsSharingScreen(false);
    await waitForScreenSharingToStop();

    let localStream;
    try {
      localStream = await chatroomRef.current.getLocalStream();
    } catch (error) {
      console.error("Failed to get local stream:", error);
      return;
    }

    Object.values(peerList).forEach((pc) => {
      pc.getSenders().forEach((sender) => {
        const newTrack = localStream
          .getTracks()
          .find((t) => t.kind === sender.track.kind);
        sender.replaceTrack(newTrack);
      });
    });

    chatroomRef.current.updateVideoStream(localStream);
  }

  function waitForScreenSharingToStop() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (chatroomRef.current.getLocalStream()) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }
  function createPeerConnection(peer_id) {
    console.log("createPeerConnection");
    peerList[peer_id] = new RTCPeerConnection(PC_CONFIG);

    peerList[peer_id].onicecandidate = (event) => {
      handleICECandidateEvent(event, peer_id);
    };
    peerList[peer_id].ontrack = (event) => {
      handleTrackEvent(event, peer_id);
      if (isRecording) {
        addToRecordingMix(event.streams[0]);
      }
    };

    peerList[peer_id].onnegotiationneeded = () => {
      handleNegotiationNeededEvent(peer_id);
    };
  }

  function handleNegotiationNeededEvent(peer_id) {
    console.log("Peer connection state: ", peerList[peer_id].connectionState);

    peerList[peer_id]
      .createOffer()
      .then((offer) => {
        return peerList[peer_id].setLocalDescription(offer);
      })
      .then(() => {
        console.log(`sending offer to <${peer_id}> ...`);
        sendViaServer({
          type: "SessionDescription", //sending offer
          payload: {
            senderId: mySid,
            targetId: peer_id,
            sdp: peerList[peer_id].localDescription.sdp,
            type: peerList[peer_id].localDescription.type,
          },
        });
      })
      .catch((e) => console.log("[ERROR] ", e));
  }

  function handleOfferMsg(msg) {
    console.log("handleOfferMsg");
    let peer_id = msg["payload"]["senderId"];
    console.log("msg", msg);

    console.log(`offer recieved from <${peer_id}>`);

    createPeerConnection(peer_id);

    let desc = new RTCSessionDescription({
      type: msg.payload.type,
      sdp: msg.payload.sdp,
    });

    peerList[peer_id]
      .setRemoteDescription(desc)
      .then(() => {
        let local_stream = chatroomRef.current.getLocalStream();
        if (audioToPatientDisabled && peerIdIsPatient[peer_id]) {
          let videoTrack = local_stream.getVideoTracks()[0];
          if (videoTrack) {
            peerList[peer_id].addTrack(videoTrack, local_stream);
          }
        } else {
          local_stream?.getTracks()?.forEach((track) => {
            console.log("adding tracks...");
            peerList[peer_id].addTrack(track, local_stream);
          });
        }
      })
      .then(() => {
        console.log("creating answer...");
        return peerList[peer_id].createAnswer();
      })
      .then((answer) => {
        console.log("setting local description...");
        return peerList[peer_id].setLocalDescription(answer);
      })
      .then(() => {
        console.log(`sending answer to <${peer_id}> ...`);
        sendViaServer({
          type: "SessionDescription", //sending answer
          payload: {
            senderId: mySid,
            targetId: peer_id,
            sdp: peerList[peer_id].localDescription.sdp,
            type: peerList[peer_id].localDescription.type,
          },
        });
      })
      .catch((e) => console.log("[ERROR] ", e));
  }

  function handleAnswerMsg(msg) {
    console.log("handleAnswerMsg");
    let peer_id = msg["payload"]["senderId"];
    console.log(`answer recieved from <${peer_id}>`);
    let desc = new RTCSessionDescription({
      type: msg.payload.type,
      sdp: msg.payload.sdp,
    });

    peerList[peer_id].setRemoteDescription(desc);
  }

  function handleICECandidateEvent(event, peer_id) {
    console.log("handleICECancidate");
    if (event.candidate) {
      sendViaServer({
        type: "IceCandidate",
        payload: {
          senderId: mySid,
          targetId: peer_id,
          candidate: event.candidate.candidate,
          sdpMLineIndex: event.candidate.sdpMLineIndex,
          sdpMid: event.candidate.sdpMid,
        },
      });
    }
  }

  function handleNewICECandidateMsg(msg) {
    console.log("handleNewICECandidateMsg msg", msg);
    var candidate = new RTCIceCandidate({
      candidate: msg.payload.candidate,
      sdpMid: msg.payload.sdpMid,
      sdpMLineIndex: msg.payload.sdpMLineIndex,
    });
    peerList[msg["payload"]["senderId"]]
      .addIceCandidate(candidate)
      .catch((e) => console.log("[ERROR] ", e));
  }

  function closeConnection(peerId) {
    console.log("closeConnection");
    if (peerId in peerList) {
      if (peerList[peerId]) {
        peerList[peerId].close();
        peerList[peerId].onicecandidate = null;
        peerList[peerId].ontrack = null;
        peerList[peerId].onnegotiationneeded = null;
      }
      delete peerList[peerId];
    }
  }

  function handleTrackEvent(event, peer_id) {
    console.log(`track event recieved from <${peer_id}>`);

    console.log("event.streams", event.streams);
    if (event.streams) {
      // todo: Find a better way to do this and to do addDiv
      if (chatroomRef && chatroomRef.current) {
        console.log("Calling chatroomRef.current.addTrack");
        setTimeout(() => {
          if (!peerIdIsPatient[peer_id]) {
            chatroomRef.current.addTrack(event, peer_id);

            const participantStream = event.streams[0];

            addParticipantStream(peer_id, participantStream);
            console.log(participantStreams);
          }
        }, 1000);
      }
    }
  }

  function start_webrtc() {
    console.log("peerList", peerList);
    console.log("mySid", mySid);
    // send offer to all other members
    for (let peerId in peerList) {
      if (peerId !== mySid) {
        invite(peerId);
        console.log("Calling invite() for " + peerId);
        chatroomRef.current.addDiv(
          peerId,
          peerIdDisplayName[peerId],
          peerIdIsPatient[peerId]
        );
      }
    }
  }

  function getVideoObj(peer_id) {
    // todo: implement
  }

  if (hasVideoCallEnded) {
    return <CallEndedScreen totalCallTime={totalCallTime} />;
  } else if (!hasVideoCallJoined) {
    return (
      <>
        <CheckpointScreen
          _token={token}
          guestDisplayName={guestDisplayName}
          setGuestDisplayName={setGuestDisplayName}
          isLocalAudioMuted={isLocalAudioMuted}
          isLocalVideoMuted={isLocalVideoMuted}
          setIsLocalAudioMuted={setIsLocalAudioMuted}
          setIsLocalVideoMuted={setIsLocalVideoMuted}
          selectedAudio={selectedAudio}
          selectedVideo={selectedVideo}
          setSelectedAudio={setSelectedAudio}
          setSelectedVideo={setSelectedVideo}
          patientName={patientName}
          showSelectDevicePopUp={showSelectDevicePopUp}
          setShowSelectDevicePopUp={setShowSelectDevicePopUp}
          setHasVideoCallJoined={setHasVideoCallJoined}
          setHasAskedToJoin={setHasAskedToJoin}
          joinedPeerListCount={joinedPeerListCount}
        />
        <ErrorPopUp isOpen={isErrorPopupOpen} handleClose={closePopup} />
      </>
    );
  } else {
    return (
      <Chatroom
        isLocalAudioMuted={isLocalAudioMuted}
        isLocalVideoMuted={isLocalVideoMuted}
        isAdmin={isAdmin}
        removePeer={removePeer}
        isSomeOneElseRecording={isSomeOneElseRecording}
        setPeerSharingScreenId={setPeerSharingScreenId}
        peerSharingScreenId={peerSharingScreenId}
        isPatientAudioMuted={isPatientAudioMuted}
        isPatientVideoMuted={isPatientVideoMuted}
        setIsLocalAudioMuted={setIsLocalAudioMuted}
        setIsLocalVideoMuted={setIsLocalVideoMuted}
        showSelectDevicePopUp={showSelectDevicePopUp}
        mutePeer={mutePeer}
        selectedAudio={selectedAudio}
        selectedVideo={selectedVideo}
        setSelectedAudio={setSelectedAudio}
        setSelectedVideo={setSelectedVideo}
        setShowSelectDevicePopUp={setShowSelectDevicePopUp}
        setAdmitAllowedLobbyMember={setAdmitAllowedLobbyMember}
        setDeclineDiscardedLobbyMember={setDeclineDiscardedLobbyMember}
        setTotalCallTime={setTotalCallTime}
        askToJoinLobby={askToJoinLobby}
        peerList={peerList}
        participantStates={participantStates}
        patientName={patientName}
        mySid={mySid}
        ref={chatroomRef}
        patientViewUrl={patientViewUrl}
        isRecording={isRecording}
        startRecording={startRecording}
        stopRecording={stopRecording}
        joinedPeerListCount={joinedPeerListCount}
        endCallButtonClicked={() => videoCallEndedSelf()}
        isSharingScreen={isSharingScreen}
        toggleScreenShare={toggleScreenShare}
      />
    );
  }
}

export default LanaWebrtcApp;
