import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
} from "react";
import Button from "../../Components/Buttons";
import ControlButton from "../../Components/ControlButtons";
import IncomingVideo from "../../Components/IncomingVideo";
import LocalVideoStream from "../../Components/LocalVideoStream";
import styles from "./style.module.scss";
import Utilities from "../../Utils/utilities";
import Lobby from "../Lobby";
import Popup from "../../Components/Popup";

const Chatroom = forwardRef(
  (
    {
      joinedPeerListCount,
      isLocalAudioMuted,
      askToJoinLobby,
      mutePeer,
      isAdmin,
      removePeer,
      peerSharingScreenId,
      setIsLocalAudioMuted,
      setAdmitAllowedLobbyMember,
      setDeclineDiscardedLobbyMember,
      showSelectDevicePopUp,
      setShowSelectDevicePopUp,
      participantStates,
      isLocalVideoMuted,
      setIsLocalVideoMuted,
      isPatientAudioMuted,
      isPatientVideoMuted,
      selectedAudio,
      selectedVideo,
      setSelectedAudio,
      setSelectedVideo,
      patientViewUrl,
      isRecording,
      isSomeOneElseRecording,
      startRecording,
      stopRecording,
      endCallButtonClicked,
      setTotalCallTime,
      isSharingScreen,
      toggleScreenShare,
    },
    ref
  ) => {
    const localVideoStreamRef = useRef();
    const [lobbyActive, setLobbyActive] = useState(false);
    const [startTime, setStartTime] = useState(new Date());
    const [elapsedTime, setElapsedTime] = useState({ minutes: 0, seconds: 0 });
    const [randomColor, setRandomColor] = useState(getRandomColor());
    const [isPopupOpen, setPopupOpen] = useState(false);
    // const [lastPinnedVideoId, setLastPinnedVideoId] = useState(null);
    // const [pinnedVideoPeerId, setPinnedVideoPeerId] = useState(null);
    const handleOpenPopup = () => {
      setPopupOpen(true);
    };

    const handleClosePopup = () => {
      setPopupOpen(false);
    };

    useEffect(() => {
      console.log("lobbyActive", lobbyActive)
    }, [lobbyActive]);

    function getRandomColor() {
      const colors = ["#5D9EEE", "#4CC2C9", "#5D9EEE"];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    }

    useEffect(() => {
      setRandomColor(getRandomColor());
    }, []);
    useEffect(() => {
      rebalanceChildren();

      if(joinedPeerListCount > 0) {
        document.getElementById("video-grid-container-id").style.backgroundColor = "transparent";
      }
    }, [joinedPeerListCount, lobbyActive]);

    useEffect(() => {
      console.log("participantStates updated in chatroom", participantStates);

      for (const peerId in participantStates) {
        const nameID = "name-id-" + peerId;
        const nameDiv = document.getElementById(nameID);

        if (nameDiv) {
          const audioMuted = participantStates[peerId]?.audioMuted;

          if (
            audioMuted &&
            !document.getElementById(`mute-indicator-div-${peerId}`)
          ) {
            const muteIndicatorDiv = document.createElement("div");
            muteIndicatorDiv.id = `mute-indicator-div-${peerId}`;
            muteIndicatorDiv.classList.add(styles.muteIndicatorDiv);
            muteIndicatorDiv.innerHTML =
              '<img src="/assets/mic-off-small.svg"  />';
            nameDiv.appendChild(muteIndicatorDiv);
          } else if (
            !audioMuted &&
            document.getElementById(`mute-indicator-div-${peerId}`)
          ) {
            const muteIndicatorDivToRemove = document.getElementById(
              `mute-indicator-div-${peerId}`
            );
            nameDiv.removeChild(muteIndicatorDivToRemove);
          }
        }
      }
    }, [participantStates]);
    useEffect(() => {
      if (!peerSharingScreenId) {
        if (document.getElementById("presenting-indicator-div")) {
          const presentingDiv = document.getElementById(
            "presenting-indicator-div"
          );
          presentingDiv.parentNode.removeChild(presentingDiv);
        }
      }
    }, [peerSharingScreenId, participantStates]);

    useEffect(() => {
      const intervalId = setInterval(() => {
        const currentTime = new Date();
        const elapsedMilliseconds = currentTime - startTime;
        const elapsedMinutes = Math.floor(elapsedMilliseconds / (1000 * 60));
        const elapsedSeconds = Math.floor(
          (elapsedMilliseconds % (1000 * 60)) / 1000
        );
        setElapsedTime({ minutes: elapsedMinutes, seconds: elapsedSeconds });
      }, 1000);

      return () => clearInterval(intervalId);
    }, [startTime]);

    const formattedTime = `${String(elapsedTime.minutes).padStart(
      2,
      "0"
    )}:${String(elapsedTime.seconds).padStart(2, "0")}`;

    useImperativeHandle(ref, () => ({
      addDiv(peer_id, displayName, isPatient) {
        let peerDivId = "div-id-" + peer_id;

        if (document.getElementById(peerDivId)) {
          return;
        }

        const videoDiv = document.createElement("div");
        videoDiv.classList.add(styles.videoDiv);
        videoDiv.id = peerDivId;
        videoDiv.style.width = "100%";
        videoDiv.style.height = "100%";
        videoDiv.style.borderRadius = "12px";
        videoDiv.style.overflow = "hidden";
        videoDiv.style.position = "relative";
        // videoDiv.addEventListener("click", () => {
        //   setPinnedVideoPeerId(peer_id);
        // });
        if (isPatient) {
          videoDiv.style.backgroundColor = "white";
          var iframe = document.createElement("iframe");
          iframe.frameBorder = 0;
          iframe.width = "100%";
          iframe.height = "100%";
          iframe.id = "patient-view-iframe";
          iframe.style.position = "relative";
          iframe.setAttribute("src", patientViewUrl);
          iframe.allow = "microphone";
          videoDiv.appendChild(iframe);
          iframe.onload = () => {
            passInitialDataToPatientViewIframe();
          }
        }

        const nameDiv = document.createElement("div");
        nameDiv.classList.add(styles.nameBox);
        nameDiv.textContent = displayName;
        nameDiv.id = "name-id-" + peer_id;

        videoDiv.appendChild(nameDiv);

        document
          .getElementById("video-grid-container-id")
          .appendChild(videoDiv);
        requestAnimationFrame(() => {
          videoDiv.classList.add(styles.videoDivVisible);
        });

        rebalanceChildren();
      },
      addTrack(event, peer_id) {
        console.log("Inside addTrack of Chatroom");

        let videoElementId = "video-id-" + peer_id;
        let peerDivId = "div-id-" + peer_id;

        if (document.getElementById(videoElementId)) {
          console.log("videoElementId not found, returning");
          return;
        }

        if (!document.getElementById(peerDivId)) {
          console.log("peerDivId not found, returning");
          return;
        }

        const video = document.createElement("video");
        video.id = videoElementId;

        video.srcObject = event.streams[0];
        video.autoplay = true;
        video.style.width = "100%";
        video.style.height = "auto";
        video.style.borderRadius = "12px";

        document.getElementById(peerDivId).appendChild(video);
      },
      removeVideoElement(peer_id) {
        let videoElementId = "video-id-" + peer_id;
        let peerDivId = "div-id-" + peer_id;

        let videoObject = document.getElementById(videoElementId);
        if (videoObject) {
          if (videoObject.srcObject) {
            videoObject.srcObject
              ?.getTracks()
              ?.forEach((track) => track.stop());
          }
          if (videoObject.hasAttribute("srcObject")) {
            videoObject.removeAttribute("srcObject");
          }
          if (videoObject.hasAttribute("src")) {
            videoObject.removeAttribute("src");
          }
          document.getElementById(videoElementId).remove();
        }
        document.getElementById(peerDivId).remove();

        rebalanceChildren();
      },
      getLocalStream() {
        return localVideoStreamRef.current.getLocalStream();
      },
      updateScreenStream(screenStream) {
        localVideoStreamRef.current.updateScreenStream(screenStream);
      },
      updateVideoStream(localVideoStream) {
        localVideoStreamRef.current.updateVideoStream(localVideoStream);
      },
    }));

    const rebalanceChildren = () => {
      let videoGridContainer = document.getElementById(
        "video-grid-container-id"
      );
      let children = videoGridContainer.children;

      let boxesCount = children.length - 1;
      let aspectRatio = 1.77777778;

      if (boxesCount > 0) {
        document.getElementById("video-grid-none-text").style.display = "none";
      } else {
        document.getElementById("video-grid-none-text").style.display = "block";
      }

      let maxWidth = Utilities.findMaxWidth(
        videoGridContainer.offsetHeight,
        videoGridContainer.offsetWidth,
        10,
        1 / aspectRatio,
        boxesCount
      );

      for (var i = 0; i < children.length; i++) {
        var divChild = children[i];
        divChild.style.maxWidth = maxWidth + "px";
        divChild.style.maxHeight = maxWidth / aspectRatio + "px";
      }
    };
    useEffect(() => {
      window.addEventListener("resize", rebalanceChildren);
      return () => {
        window.removeEventListener("resize", rebalanceChildren);
      };
    }, []);
    // Function to calculate the area based on the increment
    // function updatePinnedVideoId() {
    //   if (
    //     (!lastPinnedVideoId || lastPinnedVideoId !== peerSharingScreenId) &&
    //     peerSharingScreenId
    //   ) {
    //     return peerSharingScreenId;
    //   } else if (
    //     (!lastPinnedVideoId || lastPinnedVideoId !== pinnedVideoPeerId) &&
    //     pinnedVideoPeerId
    //   ) {
    //     return pinnedVideoPeerId;
    //   }
    // }

    // function pinVideo(id) {
    //   let pinnedVideoId = "div-id-" + id;
    //   setLastPinnedVideoId(id);
    //   let pinnedVideo = document.getElementById(pinnedVideoId);
    //   pinnedVideo.style.maxWidth = "";
    //   pinnedVideo.style.maxHeight = "";
    //   let presentingIndicatorDiv = document.createElement("div");
    //   presentingIndicatorDiv.id = "presenting-indicator-div";
    //   presentingIndicatorDiv.classList.add(styles.presentingDiv);
    //   presentingIndicatorDiv.textContent = "Presenting";
    //   presentingIndicatorDiv.style.position = "absolute";
    //   presentingIndicatorDiv.style.top = "0";
    //   presentingIndicatorDiv.style.right = "0";
    //   if (peerSharingScreenId === id) {
    //     pinnedVideo.appendChild(presentingIndicatorDiv);
    //   }
    //   document.getElementById("pinned-video").appendChild(pinnedVideo);
    //   rebalanceChildren();
    // }
    // function removePinnedVideo(id) {
    //   let pinnedVideoId = "div-id-" + id;
    //   let pinnedVideo = document.getElementById(pinnedVideoId);
    //   let presentingIndicatorDiv = document.getElementById(
    //     "presenting-indicator-div"
    //   );
    //   if (presentingIndicatorDiv) {
    //     presentingIndicatorDiv.remove();
    //   }

    //   if (pinnedVideo) {
    //     document
    //       .getElementById("video-grid-container-id")
    //       .appendChild(pinnedVideo);
    //     // document.getElementById("pinned-video").removeChild(pinnedVideo)
    //   }

    //   if (id === pinnedVideoPeerId) {
    //     setPinnedVideoPeerId(null);
    //   }
    //   rebalanceChildren();
    // }
    // function replacePinnedVideo(old_pinned_video_id, new_pinned_video_id) {
    //   setLastPinnedVideoId(new_pinned_video_id);
    //   removePinnedVideo(old_pinned_video_id);
    //   pinVideo(new_pinned_video_id);
    // }

    function passInitialDataToPatientViewIframe() {

      // Check if iframe is ready by sending ping messages
      let pingAttempts = 0;
      const maxPingAttempts = 25;
      const pingInterval = 500;
      let pingIntervalId;

      // Handle messages from iframe
      const messageHandler = (event) => {
        console.log('Received event from iframe:', event);
        console.log('Received data from iframe:', event.data);
        if (event.data) {
          try {
            const data = JSON.parse(event.data);
            console.log('Parsed data from iframe:', data);
            if (data.type === 'pong') {
              console.log('Received pong message from iframe');
              clearInterval(pingIntervalId);
              window.removeEventListener('message', messageHandler);
              iframeInitialized();
            }
          } catch (e) {
            console.error('Error parsing message:', e);
          }
        }
      };

      window.addEventListener('message', messageHandler);

      pingIntervalId = setInterval(() => {
        if (pingAttempts >= maxPingAttempts) {
          clearInterval(pingIntervalId);
          window.removeEventListener('message', messageHandler);
          console.error('Iframe failed to respond to ping messages');
          return;
        } else {
          console.log('Sending ping attempt:', pingAttempts);
        }
        
        passDataToPatientViewIframe({ type: 'ping' });
        pingAttempts++;
      }, pingInterval);
    }

    function iframeInitialized() {
      console.log("Passing to iframe isPatientAudioMuted", isPatientAudioMuted);
      passDataToPatientViewIframe({
        type: "isPatientAudioMuted",
        muteValue: isPatientAudioMuted === true,
      });

      console.log("Passing to iframe isLocalAudioMuted", isLocalAudioMuted);
      passDataToPatientViewIframe({
        type: "isLocalAudioMuted",
        muteValue: isLocalAudioMuted === true,
      });
    }

    function passDataToPatientViewIframe(dataToPass) {
      if(document.getElementById("patient-view-iframe")) {
        document.getElementById("patient-view-iframe").contentWindow.postMessage(JSON.stringify(dataToPass), '*');
      }
    }

    // // useEffect(() => {
    //   console.log("Pinned Video Peer ID", pinnedVideoPeerId);
    //   console.log("Peer Sharing Screen ID", peerSharingScreenId);
    // }, [pinnedVideoPeerId, peerSharingScreenId]);
    // useEffect(() => {
      // if (
      //   (peerSharingScreenId || pinnedVideoPeerId) &&
      //   joinedPeerListCount > 1
      // ) {
        // const videoToBePinned = updatePinnedVideoId();
        // console.log("New", videoToBePinned, "Old", lastPinnedVideoId);
        // if (
        //   lastPinnedVideoId &&
        //   videoToBePinned &&
        //   lastPinnedVideoId !== videoToBePinned
        // ) {
        //   // console.log(
        //   //   "Replacing Pinned Video ",
        //   //   lastPinnedVideoId,
        //   //   videoToBePinned
        //   // );
        //   // replacePinnedVideo(lastPinnedVideoId, videoToBePinned);
        // } else if (videoToBePinned && !lastPinnedVideoId) {
        //   pinVideo(videoToBePinned);
        // }
      // }
      // if (
      //   (peerSharingScreenId == null &&
      //     pinnedVideoPeerId == null &&
      //     lastPinnedVideoId) ||
      //   (lastPinnedVideoId && joinedPeerListCount == 1)
      // ) {
      //   console.log("Removing Pinned Video");
      //   removePinnedVideo(lastPinnedVideoId);
      //   setLastPinnedVideoId(null);
      // }
    // }, [peerSharingScreenId, pinnedVideoPeerId, joinedPeerListCount]);

    useEffect(() => {
      console.log("Inside isPatientAudioMuted", isPatientAudioMuted);
      passDataToPatientViewIframe({
        type: "isPatientAudioMuted",
        muteValue: isPatientAudioMuted === true,
      });
    }, [isPatientAudioMuted]);

    useEffect(() => {
      console.log("Inside isLocalAudioMuted", isLocalAudioMuted);
      passDataToPatientViewIframe({
        type: "isLocalAudioMuted",
        muteValue: isLocalAudioMuted === true,
      })
    }, [isLocalAudioMuted]);

    useEffect(() => {
      console.log("Inside isPatientVideoMuted", isPatientVideoMuted);
      if (isPatientVideoMuted) {
        let patientViewIframe = document.getElementById("patient-view-iframe");
        if (patientViewIframe && patientViewIframe.parentNode) {
          const overlay = document.createElement("div");
          overlay.id = "patient-view-overlay-div";
          overlay.style.position = "absolute";
          overlay.style.top = "0";
          overlay.style.left = "0";
          overlay.style.width = "100%";
          overlay.style.height = "100%";
          overlay.style.backgroundColor = "black";
          overlay.style.opacity = "1";
          overlay.style.zIndex = "1000";

          patientViewIframe.parentNode.appendChild(overlay);
        }
      } else {
        const overlay = document.getElementById("patient-view-overlay-div");
        if (overlay) {
          overlay?.parentNode.removeChild(overlay);
        }
      }
    }, [isPatientVideoMuted]);
    const memoizedLocalVideoStream = useMemo(
      () => (
        <LocalVideoStream
          isLocalAudioMuted={isLocalAudioMuted}
          isLocalVideoMuted={isLocalVideoMuted}
          showSelectDevicePopUp={showSelectDevicePopUp}
          setShowSelectDevicePopUp={setShowSelectDevicePopUp}
          selectedAudio={selectedAudio}
          selectedVideo={selectedVideo}
          setSelectedAudio={setSelectedAudio}
          setSelectedVideo={setSelectedVideo}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "12px",
            overflow: "hidden",
            position: "relative",
            display: joinedPeerListCount > 1 ? "block" : "none",
          }}
          updateVideoRef={(videoRef) => {
            console.log("Updating videoRefFromChild in Chatroom", videoRef);
          }}
          ref={localVideoStreamRef}
          isSharingScreen={isSharingScreen}
        />
      ),
      [
        isLocalAudioMuted,
        isLocalVideoMuted,
        isSharingScreen,
        joinedPeerListCount,
      ]
    );

    return (
      <>
        <div className={styles.callRoom}>
         <div className={styles.headerDivWrapper}>
         <div
           className={styles.headerDiv}
          >
            <div
              className={styles.callDetails}
            >
              <p
                className={styles.roomName}
              >
                {" "}
                Room 306-A
              </p>
              {/* <div className={styles.privacySecureContainer}>
               
                  <img src="/assets/icn-lock-shield.png" />
               
                <p className={styles.privacySecuredText}>PRIVACY SECURED</p>
              </div> */}
            </div>
            <div className={styles.imgContainer}>
              <img
                className="hospital-logo"
                src="/logo.png"
                style={{ width: "64.4px", height: "24px" }}
              />
            </div>
            {isSomeOneElseRecording || isRecording ? (
              <div
                className={styles.callDetails}
                style={{ display: "flex", padding: "0rem" }}
              >
                {isRecording && (
                  <p
                    style={{
                      padding: "0",
                      margin: "0",
                     
                      paddingLeft: "1rem",
                    }}
                  >
                    You Have Started Recording
                  </p>
                )}
                {isSomeOneElseRecording && (
                  <p
                    style={{
                      padding: "0",
                      margin: "0",
                     
                      paddingLeft: "1rem",
                    }}
                  >
                    Call Is Being Recorded
                  </p>
                )}
              </div>
            ) : null}
            <div style={{ padding:"2px 8px", backgroundColor:"#555558",height:"28px",color:"#FFFFFF", display:"flex",alignItems:"center",borderRadius:"4px", gap:"4px",width:"70px",justifyContent:"center"  }}>
              <p className={styles.timeText}>{formattedTime}</p>
            </div>
          </div>

         </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              maxHeight: "85vh",
            }}
          >
            <div
              className={styles.videoContainer}
              style={lobbyActive ? { flex: "75%" } : {}}
            >
              {/* <div
                id="pinned-video"
                className={
                  (peerSharingScreenId || pinnedVideoPeerId) &&
                  joinedPeerListCount > 1
                    ? styles.pinnedVideoActiveDiv
                    : null
                }
              >
                <Button.IconButton
                  text={"Unpin"}
                  style={
                    pinnedVideoPeerId &&
                    !peerSharingScreenId &&
                    joinedPeerListCount > 1
                      ? {
                          display: "block",
                          position: "absolute",
                          top: "50px",
                          right: "50px",
                          zIndex: "400",
                          backgroundColor: "#8549FF",
                          color: "#FFFFFF",
                        }
                      : { display: "none" }
                  }
                  onClickHandler={() => {
                    setPinnedVideoPeerId(null);
                  }}
                />
              </div> */}

              <div className={styles.videoGridContainerWrapper}>
                <div
                  id="video-grid-container-id"
                  className={styles.videoGridContainer}
                  style={
                    (peerSharingScreenId) &&
                    joinedPeerListCount > 1
                      ? {
                          flex: 1,
                        }
                      : {}
                  }
                >
                  <div
                    id="video-grid-none-text"
                    style={{
                      position: "absolute",
                      top: "50%",
                      color: "white",
                      fontSize: 20,
                      transform: "translateY(-50%)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "24px",
                      }}
                    >
                      <img
                        src="/assets/loader.svg"
                        className={styles.loaderImage}
                        height={48}
                        width={48}
                      />
                      <p className={styles.waitingText}>
                      Waiting for others to join
                      </p>
                      
                    </div>
                  </div>

                  {joinedPeerListCount > 1 && memoizedLocalVideoStream}
                </div>
                {/* <IncomingVideo /> */}

                <LocalVideoStream
                  isLocalAudioMuted={isLocalAudioMuted}
                  isLocalVideoMuted={isLocalVideoMuted}
                  showSelectDevicePopUp={showSelectDevicePopUp}
                  setShowSelectDevicePopUp={setShowSelectDevicePopUp}
                  selectedAudio={selectedAudio}
                  selectedVideo={selectedVideo}
                  setSelectedAudio={setSelectedAudio}
                  setSelectedVideo={setSelectedVideo}
                  style={
                    joinedPeerListCount <= 1
                      ? {
                          position: "absolute",
                          width: "17%",
                          right: "24px",
                          bottom: "24px",
                          borderRadius: "8px",
                          border: "6px solid #2C2C2E",
                          backgroundColor: "#2C2C2E",
                          zIndex: 3000,
                          display: "block",
                          display: "flex",
                          justifyContent: "center",
                        }
                      : {
                          display: "none",
                        }
                  }
                  updateVideoRef={(videoRef) => {
                    console.log(
                      "Updating videoRefFromChild in Chatroom",
                      videoRef
                    );
                  }}
                  ref={localVideoStreamRef}
                  isSharingScreen={isSharingScreen}
                />
              </div>
            </div>

            <Lobby
              participantStates={participantStates}
              mutePeer={mutePeer}
              removePeer={removePeer}
              isAdmin={isAdmin}
              setAdmitAllowedLobbyMember={setAdmitAllowedLobbyMember}
              setDeclineDiscardedLobbyMember={setDeclineDiscardedLobbyMember}
              askToJoinLobby={askToJoinLobby}
              isLocalAudioMuted={isLocalAudioMuted}
              isLocalVideoMuted={isLocalVideoMuted}
              handleOpenPopup={handleOpenPopup}
              lobbyActive={lobbyActive}
              setLobbyActive={(active) => setLobbyActive(active)}
              randomColor={randomColor}
            />
          </div>

          <div className={styles.footerContainer}>  
            <div style={{width: 1}}></div>
           <ControlButton
              joinedPeerListCount={joinedPeerListCount}
              setAdmitAllowedLobbyMember={setAdmitAllowedLobbyMember}
              peerSharingScreenId={peerSharingScreenId}
              askToJoinLobby={askToJoinLobby}
              isSomeOneElseRecording={isSomeOneElseRecording}
              withEndCallBttn={true}
              withRecordButton={true}
              setShowSelectDevicePopUp={setShowSelectDevicePopUp}
              withScreenShareButton={false}
              withLobby={true}
              withElipsis={true}
              isRecording={isRecording}
              startRecording={startRecording}
              stopRecording={stopRecording}
              isLocalAudioMuted={isLocalAudioMuted}
              isLocalVideoMuted={isLocalVideoMuted}
              setIsLocalAudioMuted={setIsLocalAudioMuted}
              setIsLocalVideoMuted={setIsLocalVideoMuted}
              endCallButtonClicked={endCallButtonClicked}
              lobbyActive={lobbyActive}
              setLobbyActive={setLobbyActive}
              isSharingScreen={isSharingScreen}
              toggleScreenShare={toggleScreenShare}
              controlButtonsPaddingLeftStyle={126}
            />
           
             <Button.IconButton
            text={"End Session"}
            style={{
              backgroundColor: "#F94D59",
              color: "#FFFFFF",
              padding:"16px 16px",
              alignSelf:"flex-end",
              // position: "absolute",
              // bottom: "1rem",
              // right: 5,
              
            }}
            onClickHandler={() => {
              if (endCallButtonClicked) {
                endCallButtonClicked();
                setTotalCallTime(formattedTime);
              }
            }}
          />
          </div>
         
        </div>
        <Popup isOpen={isPopupOpen} onClose={handleClosePopup} />
      </>
    );
  }
);

export default Chatroom;
