import { SVGIcons } from '@lana-health/lana-react-component-library';
import React from 'react'
import styles from "./styles.module.scss";

function SuccessPopUp({color, popupText}) {
  return (
    <div
        className={styles.box}
      >
        <div className={styles.image}>
          <div className={styles.tick}>
            <SVGIcons.Tick width={38} height={29} color={color || "white"} />
          </div>
        </div>
        <div>
          <p className={styles.popupText}>{popupText}</p>
        </div>
      </div>
  )
}

export default SuccessPopUp