import Button from "../Buttons";
import AdmitAllowedLobbyMemberPopUp from "../AdmitAllowedLobbyMemberPopUp";
import styles from "./style.module.scss";


export default function ControlButton({
  askToJoinLobby,
  joinedPeerListCount,
  withLobby,
  peerSharingScreenId,
  withElipsis,
  isSomeOneElseRecording,
  withScreenShareButton,
  withRecordButton,
  setShowSelectDevicePopUp,
  setAdmitAllowedLobbyMember,
  isRecording,
  startRecording,
  stopRecording,
  lobbyActive,
  isLocalAudioMuted,
  setIsLocalAudioMuted,
  isLocalVideoMuted,
  setIsLocalVideoMuted,
  setLobbyActive,
  isSharingScreen,
  toggleScreenShare,
  controlButtonsPaddingLeftStyle,
}) {
  return (
    <div style={{paddingLeft: controlButtonsPaddingLeftStyle}} className={styles.ctrlbttns}>
      <Button.IconButton
        icon={
          isLocalAudioMuted
            ? "/assets/icn-microphone-off.svg"
            : "/assets/icn-microphone-on.svg"
        }
        style={isLocalAudioMuted ? { backgroundColor: "#F95763" } : {}}
        onClickHandler={() => {
          setIsLocalAudioMuted(!isLocalAudioMuted);
        }}
      />
      <Button.IconButton
        icon={
          isLocalVideoMuted
            ? "/assets/camera-icn-off.svg"
            : "/assets/camera-icon-on.svg"
        }
        style={isLocalVideoMuted ? { backgroundColor: "#F95763" } : {}}
        onClickHandler={() => {
          setIsLocalVideoMuted(!isLocalVideoMuted);
        }}
      />

      {/* {withRecordButton && (
        <Button.IconButton
          icon={"/assets/icn-record.svg"}
          style={
            isRecording
              ? {
                  paddingTop: "0.8rem",
                  paddingBottom: "0.8rem",
                  backgroundColor: "#555558",
                }
              : isSomeOneElseRecording
              ? {
                  opacity: "0.2",
                  cursor: "not-allowed",
                  pointerEvents: "none",
                  paddingTop: "0.8rem",
                  paddingBottom: "0.8rem",
                }
              : { paddingTop: "0.8rem", paddingBottom: "0.8rem" }
          }
          onClickHandler={isRecording ? stopRecording : startRecording}
        />
      )} */}
      {withScreenShareButton && (
        <Button.IconButton
          icon={"/assets/screen-share.svg"}
          style={
            isSharingScreen
              ? { backgroundColor: "#555558" }
              : joinedPeerListCount === 0 || peerSharingScreenId
              ? {
                  opacity: "0.4",
                  cursor: "not-allowed",
                  pointerEvents: "none",
                }
              : {}
          }
          onClickHandler={() => {
            toggleScreenShare();
          }}
        />
      )}
      {withLobby && (
        <AdmitAllowedLobbyMemberPopUp
          setAdmitAllowedLobbyMember={setAdmitAllowedLobbyMember}
          askToJoinLobby={askToJoinLobby}
          setLobbyActive={setLobbyActive}
        >
          <Button.IconButton
            icon={"/assets/icn-people.svg"}
            frequency={joinedPeerListCount + 1}
            style={{
              backgroundColor: "#555558",
              gap: 4,
            }}
            onClickHandler={() => setLobbyActive(!lobbyActive)}
          />
        </AdmitAllowedLobbyMemberPopUp>
      )}
      <Button.IconButton
        icon={"/assets/icn-more.svg"}
        style={{ backgroundColor: "#555558" }}
        onClickHandler={() => {
          setShowSelectDevicePopUp(true);
        }}
      />
    </div>
  );
}
